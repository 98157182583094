import React, { useEffect, useState } from 'react';
import * as styles from './hooks.module.scss';

import Button from '../components/ui/Button';
import ListPagination from '../components/ui/ListPagination';

function useOnLoadEffect(effectFunction: () => void): boolean {
  const [hasRunOnLoadEffect, setHasRunOnLoadEffect] = useState(false);
  useEffect(() => {
    effectFunction();
    setHasRunOnLoadEffect(true);
  }, []);
  return hasRunOnLoadEffect;
}

export function usePagination<Item>(
  items: Array<Item>,
  itemsPerPage: number,
  containerRef?: React.RefObject<HTMLDivElement>,
): {
  currentPage: number;
  currentPageItems: Item[];
  loading: boolean;
  getPageUrlPart: () => readonly [string, string | null];
  resetPagination: () => void;
  renderPagination: () => JSX.Element;
} {
  const [currentPage, setCurrentPage] = useState(1);
  const [nCardsVisible, setNCardsVisible] = useState(itemsPerPage);
  const [loading, setLoading] = useState(false);

  const currentPageItems = items.slice(
    (currentPage - 1) * nCardsVisible,
    currentPage * nCardsVisible,
  );

  const hasRunOnLoadEffect = useOnLoadEffect(() => {
    let initialPage = 1;
    const currentUrl = new URLSearchParams(window.location.search);
    const urlPageStr = currentUrl.get('page');
    if (urlPageStr && urlPageStr.match(/^\d+$/)) {
      const urlPage = parseInt(urlPageStr);
      const totalPages = Math.ceil(items.length / itemsPerPage);
      if (1 <= urlPage && urlPage <= totalPages) {
        initialPage = urlPage;
      }
    }
    setCurrentPage(initialPage || 1);
  });

  function getPageUrlPart(): readonly [string, string | null] {
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const page = totalPages > 1 ? currentPage + '' : null;
    return ['page', page];
  }

  const resetPagination = () => {
    setCurrentPage(1);
    setNCardsVisible(itemsPerPage);
  };

  const changePage = (pageNumber: number) => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
      setLoading(true);
      setTimeout(() => {
        setCurrentPage(pageNumber);
        setLoading(false);
      }, 300);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => (
    <>
      {hasRunOnLoadEffect && nCardsVisible < items.length && (
        <Button
          outlined
          onClick={() => {
            setNCardsVisible(nCardsVisible + itemsPerPage);
          }}
          className={styles.listPaginationMobileButton}
        >
          Load more
        </Button>
      )}
      {hasRunOnLoadEffect && items.length > itemsPerPage && (
        <ListPagination
          currentPage={currentPage}
          totalItems={items.length}
          itemsPerPage={nCardsVisible}
          onPrevPageClick={() => {
            changePage(currentPage - 1);
          }}
          onNextPageClick={() => {
            changePage(currentPage + 1);
          }}
          onPageClick={(pageNumber: number) => {
            changePage(pageNumber);
          }}
        ></ListPagination>
      )}
    </>
  );

  return {
    currentPage,
    currentPageItems,
    loading,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  };
}
