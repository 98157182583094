import { clsx, truncateText } from '@digital-spiders/misc-utils';
import React, { useRef } from 'react';

import type { CardType } from '../../types/types';
import { usePagination } from '../../utils/hooks';
import { useUpdateUrlFromFilters } from '@digital-spiders/react-hooks';
import Card from './Card';
import * as styles from './ItemListing.module.scss';

const ITEMS_PER_PAGE = 6;

export interface ItemListingProps {
  items: Array<CardType & { _id: string }>;
  withPagination?: boolean;
  itemWithTallerImage?: boolean;
  dontUpdateUrl?: boolean;
  itemsPerPage?: number;
  className?: string;
}

const ItemListing = ({
  items,
  withPagination,
  itemWithTallerImage,
  dontUpdateUrl,
  itemsPerPage,
  className,
}: ItemListingProps): React.ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null); // Create a ref in your component

  const { currentPage, currentPageItems, loading, getPageUrlPart, renderPagination } =
    usePagination(items, itemsPerPage || ITEMS_PER_PAGE, containerRef);

  // Update url on filters state change
  if (!dontUpdateUrl) {
    useUpdateUrlFromFilters(() => [getPageUrlPart()], [currentPage]);
  }

  return (
    <>
      <div
        className={clsx(className, styles.container, loading && styles.loading)}
        ref={containerRef}
      >
        {(withPagination ? currentPageItems : items).map((item, i) => (
          <React.Fragment key={item._id}>
            <Card
              className={styles.card}
              title={item.title}
              text={item.text ? truncateText(item.text, 2 * 45) : undefined}
              image={item.image}
              link={item.link}
              linkText={item.linkText}
              category={item.category}
              withTallerImage={itemWithTallerImage}
            />
          </React.Fragment>
        ))}
      </div>
      {withPagination && renderPagination()}
    </>
  );
};

export default ItemListing;
