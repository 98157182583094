import { clsx } from '@digital-spiders/misc-utils';
import { FaHeadphones, FaPencilAlt, FaVideo } from 'react-icons/fa';

import * as styles from './CategoryTag.module.scss';

export interface CategoryTagProps {
  title: string;
  icon?: 'pencil' | 'headphones' | 'video';
  className?: string;
}

const CategoryTag = ({ title, icon, className }: CategoryTagProps): React.ReactElement => {
  const iconConfig = {
    pencil: <FaPencilAlt />,
    headphones: <FaHeadphones />,
    video: <FaVideo />,
  };

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.title}>
        {icon && iconConfig[icon]}
        {title}
      </div>
    </div>
  );
};

export default CategoryTag;
