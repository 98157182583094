import { clsx } from '@digital-spiders/misc-utils';
import { BREAKPOINTS } from '../../styles/breakpoints';
import type { CardType } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import * as styles from './Card.module.scss';
import CategoryTag from './CategoryTag';
import EdgeCut from './EdgeCut';
import Image from './Image';
import SmartLink from './SmartLink';
import TextLink from './TextLink';

export type CardProps = CardType & {
  withTallerImage?: boolean;
  className?: string;
};

const Card = ({
  image,
  title,
  text,
  link,
  withTallerImage,
  category,
  linkText,
  className,
}: CardProps): React.ReactElement => {
  return (
    <SmartLink
      className={clsx(styles.container, className, withTallerImage && styles.withTallerImage)}
      to={getUrlFromVersatileLink(link)}
    >
      {category?.title && <CategoryTag {...category} className={styles.category} />}
      <div className={styles.imageContainer}>
        <EdgeCut
          sideHorizontal="right"
          sideVertical="top"
          className={styles.edgeCutHover}
          widths={{
            // WARNING when changing these values, also update the values in the css
            default: '64px',
            [BREAKPOINTS.tabletHorizontal]: '100px',
            [BREAKPOINTS.laptop]: '133px',
          }}
          hoverClassName={styles.container}
        />
        {image && (
          <Image
            image={image}
            alt={'Illustration of ' + title}
            className={styles.image}
            width={440}
            height={withTallerImage ? 340 : 210}
            cover
          />
        )}
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        {text && <p className={styles.text}>{text}</p>}
        <TextLink styleOnly withArrow className={clsx(styles.link, !text && styles.withMarginTop)}>
          {linkText || 'View post'}
        </TextLink>
      </div>
    </SmartLink>
  );
};

export default Card;
